@import url('https://fonts.googleapis.com/css?family=Fjalla+One');

#background-img {
	height: 500px;
	border-bottom: 2px solid #FAFCE7;
  background-image: url("../assets/pexels-photo_web.jpg");
}

#buttons {
  display: flex;
  align-items: flex-end;
}

#filter-ribbon {
  height: 150px;
  border: 1px solid black;
  background-color: #323232;
  color: #FAFCE7;
  font-family: Helvetica;
  font-size: 24px;
}

#filter-results {
  background: linear-gradient(180deg, #323232 0%, #000000 100%);
}

.name {
	color: #FAFCE7;
	font-family: Helvetica;
	font-size: 20px;
	text-align: center;
}

.genre-middle {
	color: #FFA6F1;
	font-family: Helvetica;
	font-size: 20px;
	text-align: center;
}

.result {
  border-bottom: 1px solid #D5D5D5;
  padding: 10px;
}

.ais-Hits {
   padding-bottom: 120px;
}

.ais-Hits-item {
	width: 100%;
	box-shadow: none;
	border: none;
	margin: 0;
}

.ais-RefinementList {
	ul {
		display: flex;
	}
}

#togglerbar {
	background-color: black;
	text-align: center;
	padding-top: 15px;
	padding-bottom: 15px;
	select {
		background-color: black;
    color: pink;
    width: 40%;
    height: 40px;
	}
}

.ais-SearchBox {
  width: 50%;
  padding-top: 15px;
  padding-bottom: 15px;
  margin: 0 auto;

  @media (max-width: 768px) {
	width: 75%;
   }

	input {
		background-color: #323232;
		border: 1px solid pink;
		color: white;
	}
}

.panel-area {
	border-bottom: 1px solid #EFE3AD;
}
