@import url('https://fonts.googleapis.com/css?family=Poppins');

.header {
  background-color: #000000;
  border-bottom: 2px solid #EFE3AD;
  color: white;
  font-family: 'Poppins', sans-serif;
  height: 45px;
  position: sticky;
  top: 0;
  z-index: 1000;
  margin: 0;
}

.navIcon {
    color: white;
}

#first-icon {
  background-image: url("../assets/fill67.png");
}

#signedinas {
  color: #FFA6F1;
}
