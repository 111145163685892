#venue-img {
  height: 600px;
  background-image: url("../assets/venueimg.jpeg");
  border-bottom: 2px solid #A09874;
}

#map-img {
  height: 200px;
  background-image: url("../assets/mapimage.jpeg");
}
