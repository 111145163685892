@import url('https://fonts.googleapis.com/css?family=Fjalla+One');

.leftBox {
  height: 100%;
  background-color: #333333;
  opacity: 100%;
  color: #FCFDF1;
  font-family: 'Fjalla One' initial;
  font-size: 12px;
}

.sectionBox {
  padding-bottom: 20px;
  padding-top: 20px;
  border-bottom: 2px solid gray;
}

#section-header {
  font-size: 14px;
  font-weight: 600;
  border-bottom: 1px solid #FCFDF1;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

#connect-band-name {
  color: #FFA6F1;
  font-size: 12px;
  font-weight: normal;
}

#connect-timestamp {
  color: #8c8686;
  font-size: 10px;
  font-weight: lighter;
}

.list-item {
  border-bottom: 1px solid grey;
  padding-bottom: 8px;
  padding-top: 8px;
  display: flex;
}

.leftBox__button {
  padding: 5px 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.list-item-wrapper {
  margin-right: 40px;
}

.editIcon {
  width: 13px;
  margin-right: 5px;
}
