@import url('https://fonts.googleapis.com/css?family=Poppins');

#settingsHeaderBar {
  background: #000000;
  color: #FAFCE7;
  height: 50px;
  border-bottom: 3px solid #E200C1;
  font-family: 'Poppins', sans-serif;
}

.settingsContainer {
  background: linear-gradient(180deg, #323232 0%, #000000 100%);
  color: #BDBDBD;
}

.create-form-label {
  color: #FAFCE7;
}

#band-form-container {
  background-image: url("../assets/Bg image.jpg");
  min-height: 100vh;
  height: 100%;
  color: white;
  padding-top: 50px;
  padding-bottom: 190px;
}
