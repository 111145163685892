@import url('https://fonts.googleapis.com/css?family=Poppins');

.modal-box {
      display: block;
      position: fixed; /* Stay in place */
      z-index: 1; /* Sit on top */
      left: 0;
      top: 0;
      width: 100%; /* Full width */
      height: 100%; /* Full height */
      overflow: auto; /* Enable scroll if needed */
      background-color: rgb(0,0,0); /* Fallback color */
      background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    }

    .modal-content {
      background-color: #fefefe;
      margin: 15% auto; /* 15% from the top and centered */
      padding: 20px;
      border: 1px solid #888;
      width: 60%; /* Could be more or less, depending on screen size */
      input {
        color: black;
      }
    }

    .close {
    color: #333;
    font-size: 28px;
    font-weight: bold;
    align-self: flex-end;
  }

  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  .modalTitle{
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .inputs-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .labelText{
    font-weight: 600;
    margin-right: 8px;
  }

  .customSelect {
    width: 175px;
  }