@import url('https://fonts.googleapis.com/css?family=Fjalla+One');

#carousel {
  height: 600px;
  border-bottom: 2px solid #A09874;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

#info-ribbon {
  background-color: black;
  opacity: 100%;
  color: #FFA6F1;
  font-family: 'Fjalla One', sans-serif;
  font-size: 16px;
  border-bottom: 1px solid #FFA6F1;
  padding-top: 15px;
  padding-bottom: 15px;
}

#genre-label {
  font-family: helvetica;
  font-weight: normal;
}

#band-location {
  font-family: helvetica;
  font-weight: normal;
  color: #FCFDF1;
}

#connect-box {
  text-align: center;
  font-family: helvetica;
  font-weight: normal;
}

#bottom-pane {
  color: #C8BE91;
  font-family: helvetica;
  font-weight: normal;
	background: linear-gradient(180deg, #323232 0%, #000000 100%);
}

#band-description {
  display: block;
  color: #FAFCE7;
  padding-top: 20px;
  padding-bottom: 20px;
}

.subtitle {
  color: #FCFDF1;
  font-family: 'Fjalla One', serif;
  font-size: 20px;
}

.band-member {
  color: #FFF79E;
  font-weight: bold;
  font-size: 24px;
}

.padding-0 {
    padding-right: 0;
    padding-left: 0;
}

#edit-hiatus-buttons {
  button {
    background-color: #FFA6F1;
    color: #007bff;
    cursor: pointer;
  }
}

#connectButton {
  button {
    width: 100%;
    background-color: #f2e991;
    color: #FFA6F1;
    font-weight: bold;
    font-style: italic;
    cursor: pointer;
  }
}

#prof-band-name {
  font-weight: bold;
}

#genre-box {
  text-align: right;
}

#about-text {
  color: #C8BE91;
}

.profile-container {
  min-height: 100vh;
  height: 100%;
  display: flex;
  padding-bottom: 190px;
  background-color: #333333;

  .profile-wrapper {
    display: flex;
    width: 100%;

    @media(max-width: 768px) {
      flex-direction: column-reverse;
    }

    .profile-wrapper__left {
      width: 25%;

      @media(max-width: 768px) {
        width: 100%;
      }
    }

    .profile-wrapper__right {
      flex: 1;
      background-color: #333333;
    }
  }
}
