@import url('https://fonts.googleapis.com/css?family=Poppins');

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 8px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #7A7A75;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  left: 10px;
  height: 24px;
  width: 24px;
  color: #FFA6F1;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #FFA6F1;
}

/* General sidebar styles */
.bm-menu {
  text-decoration: none;
  background: #2F2F2F;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  color: #FFF79E;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  a {
    text-decoration: none;
    color: #FFF79E;
    &:hover{
      color: #FFA6F1;
    }
  }
  div {
    margin-bottom: 15px;
  }
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  color: #FFF79E;
  padding: 0.8em;
  button {
    background-color: inherit;
    border: none;
    color: inherit;
  }
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
