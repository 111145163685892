@import url('https://fonts.googleapis.com/css?family=Fjalla+One');

#login-box {
  background-image: url("../assets/Bg image.jpg");
	font-family: 'Fjalla One', sans-serif;
  margin: 0px;
  min-height: 100vh;
  height: 100%;
}

#signup-options {
  max-width: 400px;
  color: white;
  font-family: sans-serif;
  margin: 0 auto;
}

#go-button {
  cursor: pointer;
  border-radius: 5%;
  font-weight: bold;
  border: 1px solid #FFA6F1;
  background-color: gray;
  color: #f2e991;
  padding: 6px 25px;
}

.go-bt-cntner {
  text-align: right;
}

.login-text {
  color: white;
  font-style: normal;
  font-family: 'Fjalla One', sans-serif;
  padding-top: 50px;
  input {
    border: none;
    border-bottom: 1px solid white;
    border-radius: 0px;
  }
  max-width: 400px;
}

#no-account {
  color: #f2e991;
  cursor: pointer;
}

#request-invite {
  text-decoration: underline;
  color: #FFA6F1;
  text-align: left;
  cursor: pointer;
}

#forgot-pw {
  color: #f2e991;
  cursor: pointer;
  text-decoration: underline;
}

