#footer {
  background-color: #5C5C5C;
  border-top: 2px solid #EFE3AD;
  text-align: center;
	color: #FAFCE7;
	font-family: Helvetica;
	font-size: 24px;
  position: fixed;
  width: 100%;
  bottom: 0;
  padding: 10px;
}
